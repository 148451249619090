import { extendTheme } from "@chakra-ui/react";

const colors = {
  main: "#5400ea",
  side: "#e15f43",
};

const theme = extendTheme({
  colors: {
    black: "#171821",
    brand: {
      main: colors.main,
      side: colors.side,
    },
  },
  fonts: {
    heading: `Ezra, sans-serif`,
    body: `Ezra, sans-serif`,
  },
  components: {
    Text: {
      baseStyle: {
        color: "#242533",
      },
    },
    Button: {
      variants: {
        rounded: {
          borderRadius: "100px",
          paddingX: 8,
        },
      },
    },
    UnorderedList: {
      variants: {
        skills: {
          border: "1px solid red",
        },
      },
    },
    ListItem: {},
  },
  breakpoints: {
    base: "0em", // 0px
    sm: "30em", // ~480px. em is a relative unit and is dependant on the font size.
    md: "48em", // ~768px
    lg: "62em", // ~992px
    xl: "80em", // ~1280px
    "2xl": "96em", // ~1536px
    "3xl": "110em", // ~1536px
    "4xl": "124em", // ~1536px
  },
});

export default theme;
