import {
  Box,
  Flex,
  Text,
  Button,
  Heading,
  HStack,
  GridItem,
  Grid,
  Tag,
  Link,
  useToken,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { IoGlobe, IoLogoCodepen, IoLogoGithub } from "react-icons/io5";
import projects from "../data/projects";

const options = [
  {
    id: 1,
    label: "Code",
  },
  {
    id: 2,
    label: "Graphics",
  },
  {
    id: 3,
    label: "Mixed",
  },
];

const Portfolio = () => {
  const [brandMain] = useToken("colors", ["brand.main"]);

  const [activeTab, setActiveTab] = useState(1);
  const [floaterLeft, setFloaterLeft] = useState("0px");
  const [floaterWidth, setFloaterWidth] = useState(null);
  const firstItemRef = useRef(null);

  useEffect(() => {
    if (floaterWidth === null && firstItemRef !== null) {
      setFloaterWidth(firstItemRef.current.clientWidth + "px");
    }
  }, [floaterWidth, firstItemRef]);

  return (
    <Box
      w={"83%"}
      maxWidth={"100%"}
      margin="0 auto"
      marginY={32}
      className="js-portfolio"
    >
      <Flex alignItems="center" mb={16} flexDir={{ base: "column", md: "row" }}>
        <Box flex={1}>
          <Heading
            flex="1"
            fontSize="6xl"
            color="brand.main"
            bgGradient={"radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"}
            backgroundSize={" 100% 200%"}
            backgroundPosition={"top left"}
            bgClip="text"
          >
            Portfolio
          </Heading>{" "}
        </Box>
        <Box>
          <HStack spacing={8} position={"relative"}>
            <Box
              sx={{
                position: "absolute",
                top: "100%",
                left: floaterLeft,
                width: floaterWidth,
                height: "8px",
                borderRadius: 4,
              }}
              bgGradient={"linear(to-br, #5400ea, #e15f43)"}
              transition="0.3s ease all"
            ></Box>
            {options.map((item, index) => {
              return (
                <Button
                  ref={index === 0 ? firstItemRef : null}
                  position="relative"
                  p="0"
                  bg="none"
                  borderRadius="0"
                  borderBottom={"4px solid transparent"}
                  color={item.id === activeTab ? "black" : "gray.500"}
                  _hover={{
                    color: "black",
                  }}
                  _active={{
                    color: "black",
                  }}
                  onClick={(e) => {
                    setActiveTab(item.id);
                    setFloaterLeft(e.target.offsetLeft + "px");
                    setFloaterWidth(e.target.clientWidth + "px");
                  }}
                >
                  {item.label}
                </Button>
              );
            })}
          </HStack>
        </Box>
      </Flex>
      <Box>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
          gap={8}
        >
          {projects
            .filter((item) => item.type === activeTab)
            .map((item) => {
              return (
                <GridItem>
                  <Flex height="100%" flexDir={"column"}>
                    <Box
                      background="gray.700"
                      width="100%"
                      minH={{ base: "300px", lg: "400px" }}
                      height="100%"
                      mb="-10%"
                      opacity="1"
                      borderRadius={16}
                      backgroundImage={item.image}
                      backgroundSize="cover"
                      backgroundPosition="center"
                      boxShadow="0 0 20px rgba(84, 0, 234, 0.15)"
                    ></Box>
                    <Box
                      background="white"
                      boxShadow="0 0 20px rgba(84, 0, 234, 0.15)"
                      width={{ base: "100%", lg: "70%" }}
                      // minH="100px"
                      marginLeft={{ base: "0%", lg: "15%" }}
                      opacity="1"
                      borderRadius={16}
                      // overflow="hidden"
                    >
                      <Flex flexDir="column">
                        <Box flex="1" p={8} pb={0}>
                          <Heading
                            fontSize="2xl"
                            mb={2}
                            bgGradient={"linear(to-br, #5400ea, #e15f43)"}
                            bgClip="text"
                          >
                            {item.title}
                          </Heading>
                          <Text mb={4}>{item.desc}</Text>
                          {item.tools.map((item) => {
                            return (
                              <Tag
                                size="sm"
                                mr={2}
                                mb={2}
                                padding="8px"
                                color="white"
                                background="gray.800"
                                borderRadius={"12px"}
                              >
                                {item}
                              </Tag>
                            );
                          })}
                        </Box>
                        {!item.wip && (
                          <Flex
                            // borderTop="1px solid gray"
                            // borderColor="gray.200"
                            p={8}
                            gap={4}
                          >
                            {item.repoLink.length > 0 && (
                              <Link
                                href={item.repoLink}
                                target="_blank"
                                flex="1"
                                p={4}
                                background={brandMain}
                                color="white"
                                borderRadius={"40px"}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                fontSize="20px"
                                _hover={{
                                  transform: "scale(1.02)",
                                }}
                                sx={{
                                  bgGradient: "linear(to-br, #5400ea, #e15f43)",
                                }}
                              >
                                {item.repoLink.includes("codepen") ? (
                                  <IoLogoCodepen size={32} />
                                ) : (
                                  <IoLogoGithub size={32} />
                                )}
                                <Box lineHeight={0} mb={-1} ml={2}>
                                  Code
                                </Box>
                              </Link>
                            )}
                            {item.link.length > 0 && (
                              <Link
                                href={item.link}
                                target="_blank"
                                flex="1"
                                p={4}
                                background={brandMain}
                                color="white"
                                borderRadius={"40px"}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                fontSize="20px"
                                _hover={{
                                  transform: "scale(1.02)",
                                }}
                                sx={{
                                  bgGradient: "linear(to-br, #5400ea, #e15f43)",
                                }}
                              >
                                {" "}
                                <IoGlobe size={32} />
                                <Box lineHeight={0} mb={-1} ml={2}>
                                  Live
                                </Box>
                              </Link>
                            )}
                          </Flex>
                        )}
                        {item.wip && (
                          <Box
                            paddingX={4}
                            paddingY={8}
                            textAlign="center"
                            fontStyle="italic"
                          >
                            Coming Soon
                          </Box>
                        )}
                      </Flex>
                    </Box>
                  </Flex>
                </GridItem>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
};

export default Portfolio;
