import { Box, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import CustomIcon from "./CustomIcon";
import SectionFade from "./SectionFade";

const data = [
  {
    title: "11+ Years of Experience",
    body: "With a decade of experience as both a software engineer and designer, I bring a wealth of expertise to every project. From the boring to exciting and everything between, I've probably ran into it.",
    icon: (style) => {
      return <CustomIcon style={style} type="code" />;
    },
  },
  {
    title: "Multi-Disciplined",
    body: "With an understanding from concept to deliver, I use my cross-skill approach to bridge disciplines and unlock innovative solutions.",
    icon: (style) => {
      return <CustomIcon style={style} type="lab" />;
    },
  },
  {
    title: "Learning Oriented",
    body: "As a self-taught professional, I'm quick to learn and eager to expand my skills. My forever-learner mindset has set me up for continuous growth and adaptability.",
    icon: (style) => {
      return <CustomIcon style={style} type="working" />;
    },
  },
  {
    title: "Solution Driven",
    body: "With a passion for solving problems I pour myself into my work. Whether in a individual setting or a team setting, I'm always striving to find the best way to deliver value, contribute meaningfully while promoting a positive work culture.",
    icon: (style) => {
      return <CustomIcon style={style} type="remote" />;
    },
  },
];

const About = () => {
  return (
    <>
      <Box w={"83%"} maxWidth={"100%"} margin="0 auto" marginY={64}>
        <Heading
          mb={32}
          fontSize="6xl"
          color="brand.main"
          bgGradient={"radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"}
          backgroundSize={" 100% 200%"}
          backgroundPosition={"top left"}
          bgClip="text"
          textAlign={{ base: "center", lg: "left" }}
        >
          About Me
        </Heading>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
          gap={28}
        >
          {data.map((item) => {
            return (
              <GridItem>
                <Flex>
                  <Box mr={10}>
                    <Box
                      w={{ base: 28, xl: 40, "2xl": 60, "4xl": 80 }}
                      h={{ base: 28, xl: 40, "2xl": 60, "4xl": 80 }}
                      // background="gray.200"
                      borderRadius={80}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      opacity={1}
                      position={"relative"}
                      transition="0.3s ease all"
                      p={{ base: 4, xl: 10, "2xl": 14, "4xl": 20 }}
                    >
                      {item.icon()}
                      <Box
                        sx={{
                          top: "50%",
                          left: "50%",
                          position: "absolute",
                          borderRadius: {
                            base: 28 * 0.8,
                            xl: 40 * 0.8,
                            "2xl": 60 * 0.8,
                            "4xl": 80 * 0.8,
                          },
                          width: { base: 28, xl: 40, "2xl": 60, "4xl": 80 },
                          height: { base: 28, xl: 40, "2xl": 60, "4xl": 80 },
                          // background: "blue",
                          // filter: "blur(20px)",
                          transform: "translate(-50%,-50%)",
                          bgGradient:
                            "radial-gradient(circle, #5400ea 0%, #e15f43 100% )",
                          zIndex: "-1",
                        }}
                        transition="0.3s ease all"
                      ></Box>
                    </Box>
                  </Box>
                  <Box
                    flex={1}
                    pt={4}
                    display="flex"
                    flexDir={"column"}
                    justifyContent={"center"}
                  >
                    <Heading
                      fontSize="4xl"
                      color="brand.main"
                      bgGradient={
                        "radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"
                      }
                      backgroundSize={"200% 200%"}
                      backgroundPosition={"top left"}
                      bgClip="text"
                      mb={2}
                    >
                      {item.title}
                    </Heading>
                    <Text>{item.body}</Text>
                  </Box>
                </Flex>
              </GridItem>
            );
          })}
        </Grid>
      </Box>
      <SectionFade startColor={"white"} endColor={"black"} height={400} />
    </>
  );
};

export default About;
