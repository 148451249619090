import { Box } from "@chakra-ui/react";
import React from "react";

const SectionFade = ({ startColor, endColor, dir, height }) => {
  const gradientString = `linear(${
    dir === "up" ? "to-t" : "to-b"
  }, ${startColor}, ${endColor})`;
  return (
    <Box
      sx={{
        height: height + "px",
        bgGradient: gradientString,
      }}
    ></Box>
  );
};

export default SectionFade;
