import React from "react";
import logo from "../assets/images/logo/logo.png";

const Preloader = ({ visible }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#5400ea",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: 5,
        opacity: visible ? "1" : "0",
        transition: "0.3s ease all",
        pointerEvents: visible ? "auto" : "none",
      }}
    >
      <img
        alt="logo"
        src={logo}
        style={{
          display: "block",
          width: "120px",
          height: "120px",
        }}
      />
    </div>
  );
};

export default Preloader;
