import { Box, Flex, Grid, GridItem, Heading } from "@chakra-ui/react";

const tools = [
  {
    label: "HTML",
  },
  {
    label: "CSS",
  },
  {
    label: "Javascript",
  },
  {
    label: "AngularJS",
  },
  {
    label: "React",
  },
  {
    label: "PHP",
  },
  {
    label: "Node",
  },
  {
    label: "AWS",
  },
  {
    label: "Adobe CS",
  },
  {
    label: "Git + Github",
  },
  {
    label: "Socket.io",
  },
  {
    label: "Canvas API",
  },
  {
    label: "MySQL",
  },
  {
    label: "ChakraUI + MaterialUI",
  },
  {
    label: "Redux + Zustand",
  },
  {
    label: "Typescript",
  },
];
const skills = [
  {
    label: "Software Engineering",
  },
  {
    label: "UX/UI Design",
  },
  {
    label: "Graphic Design",
  },
  {
    label: "Problem Solving",
  },
  {
    label: "Debugging",
  },
  {
    label: "Motion Design",
  },
  {
    label: "Identity Design",
  },
  {
    label: "Team Player",
  },
  {
    label: "Autodidactic",
  },
  {
    label: "API Design",
  },
  {
    label: "Accessability",
  },
  {
    label: "QA Testing",
  },
];

const Skills = () => {
  return (
    <Box background="black" paddingY={{ base: 16 }} paddingTop={60}>
      <Box w={{ base: "88%", lg: "83%" }} maxWidth={"100%"} margin="0 auto">
        <Flex flexDir={{ base: "column", lg: "column" }}>
          <Box
            padding={16}
            background="white"
            flex="1"
            borderRadius={24}
            mb={{ base: 16, lg: 16 }}
            width={{ base: "100%", lg: "auto" }}
          >
            <Heading
              color="brand.main"
              bgGradient={"radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"}
              backgroundSize={"200% 200%"}
              backgroundPosition={"top left"}
              bgClip="text"
              mb={8}
              fontSize={{ base: "32px", lg: "40px" }}
            >
              Tools
            </Heading>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                // lg: "repeat(3, 1fr)",
                // md: "repeat(, 1fr)",
                lg: "repeat(2, 1fr)",
                xl: "repeat(3, 1fr)",
                "2xl": "repeat(4, 1fr)",
              }}
              gap={4}
            >
              {tools.map((item) => (
                <GridItem>
                  <Flex alignItems="center">
                    <Box
                      color="gray.600"
                      fontSize="xl"
                      fontWeight="bold"
                      pl={0}
                      transition="0.3s ease all"
                      _hover={{ paddingLeft: 4, color: "gray.900" }}
                      flex="1"
                    >
                      {item.label}
                    </Box>
                  </Flex>
                </GridItem>
              ))}
            </Grid>
          </Box>
          <Box
            padding={16}
            background="white"
            flex="1"
            borderRadius={24}
            width={{ base: "100%", lg: "auto" }}
          >
            <Heading
              color="brand.main"
              bgGradient={"radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"}
              backgroundSize={"200% 200%"}
              backgroundPosition={"top left"}
              bgClip="text"
              mb={8}
            >
              Skills
            </Heading>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                // lg: "repeat(3, 1fr)",
                // md: "repeat(, 1fr)",
                lg: "repeat(2, 1fr)",
                xl: "repeat(3, 1fr)",
                "2xl": "repeat(4, 1fr)",
              }}
              gap={4}
            >
              {skills.map((item) => (
                <GridItem>
                  <Flex alignItems="center">
                    <Box
                      color="gray.600"
                      fontSize="xl"
                      fontWeight="bold"
                      pl={0}
                      transition="0.3s ease all"
                      _hover={{ paddingLeft: 4, color: "gray.900" }}
                      flex="1"
                    >
                      {item.label}
                      {/* <List pl={4}>
                        <ListItem>Item</ListItem>
                      </List> */}
                    </Box>
                  </Flex>
                </GridItem>
              ))}
            </Grid>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Skills;
