import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { TiArrowDown } from "react-icons/ti";
import {
  IoLogoCss3,
  IoLogoFigma,
  IoLogoHtml5,
  IoLogoJavascript,
  IoLogoNodejs,
  IoLogoReact,
} from "react-icons/io5";
import pfpImage from "../assets/images/pfp.jpg";
import { SiAdobe } from "react-icons/si";
import SectionFade from "./SectionFade";

const logos = [
  {
    icon: <IoLogoHtml5 size={52} />,
    color: "#3557f0",
    label: "HTML",
  },
  {
    icon: <IoLogoCss3 size={52} />,
    color: "#ff911c",
    label: "CSS",
  },
  {
    icon: <IoLogoJavascript size={52} />,
    color: "#ffe138",
    label: "Javascript",
  },
  { icon: <IoLogoNodejs size={52} />, color: "#33ff1c", label: "Node.js" },
  { icon: <IoLogoReact size={52} />, color: "#1cceff", label: "React" },
  {
    icon: <SiAdobe size={44} />,
    color: "#ff331c",
    label: "Adobe Creative Suite",
  },
  { icon: <IoLogoFigma size="52" />, color: "#bf1cff", label: "Figma" },
];

const Hero = () => {
  const arrowClickHandler = () => {
    const elm = document.querySelector(".js-portfolio");
    let elmPos = elm.getBoundingClientRect().top;
    let scrollPos = window.scrollY || window.pageYOffset;
    let topVal = elmPos + scrollPos;
    window.scrollTo({
      left: 0,
      top: topVal - parseInt(getComputedStyle(elm).marginTop, 10),
      behavior: "smooth",
    });
  };
  return (
    <>
      <Box background="black" position="relative" overflow="hidden">
        <Flex
          w={"83%"}
          maxWidth={"100%"}
          minH="100vh"
          margin="0 auto"
          paddingY={16}
          alignItems={{ base: "flex-start", xl: "center" }}
          justifyContent={{ base: "flex-start", xl: "center" }}
          color="white"
          flexDirection={{ base: "column", xl: "row" }}
          position="relative"
          zIndex={2}
        >
          <Box
            flex="2"
            minWidth="20%"
            color="white"
            order={{ base: 2, xl: 1 }}
            lineHeight={1}
          >
            <Text fontSize={34} opacity={0.5} mb={1} color="white">
              Hi, I'm
            </Text>
            <Heading fontSize={{ base: 24, lg: 40, "2xl": 60 }} pr={16} mb={4}>
              <Text
                color="brand.main"
                bgGradient={
                  "radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"
                }
                backgroundSize={" 150% 200%"}
                backgroundPosition={"top left"}
                bgClip="text"
                fontSize={{ base: 64, lg: 100, "2xl": 140 }}
                lineHeight={1}
              >
                Victor Fuentes
              </Text>
              Front-End Software Engineer <br />& Visual Designer
            </Heading>
            <Box>
              {logos.map((item) => (
                <Box
                  w={10}
                  h={10}
                  borderRadius={60}
                  mr={2}
                  display="inline-block"
                  color="white"
                  transition="0.3s ease all"
                  opacity={0.5}
                  _hover={{
                    opacity: 1,
                    color: item.color,
                    transform: "scale(1.1)",
                    "&:hover .logoText": {
                      opacity: 1,
                      top: "52px",
                    },
                  }}
                >
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    aria-label={item.label}
                    position={"relative"}
                  >
                    {item.icon}
                    {/* <Box
                    className={"logoText"}
                    sx={{
                      transition: "0.3s ease all",
                      opacity: 0,
                      textAlign: "center",
                      position: "absolute",
                      top: "56px",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {item.label}
                  </Box> */}
                  </Flex>
                </Box>
              ))}
            </Box>
            <Box mt={10} color="white">
              <Button
                borderRadius={"100px"}
                transition="0.3s ease all"
                p={8}
                onClick={arrowClickHandler}
                display="flex"
                alignItems={"center"}
                lineHeight="1"
                background="transparent"
                boxShadow={"inset 0 0 0 2px white"}
                color="white"
                _hover={{
                  background: "brand.main",
                  bgGradient: "linear(to-br, #5400ea, #e15f43)",
                }}
                width={{ base: "100%", md: "auto" }}
              >
                <TiArrowDown
                  aria-label="Scroll down"
                  style={{
                    marginTop: "-4px",
                    // marginRight: "16px",
                    display: "inline",
                  }}
                  size={48}
                />
                {/* <Box fontSize={"24px"} marginTop={"4px"}>
                Find Out More
              </Box> */}
              </Button>
            </Box>
          </Box>
          {/* PFP */}
          <Box
            aria-hidden
            width={{ base: "400px", xl: "400px" }}
            height={{ base: "400px", xl: "400px" }}
            mb={{ base: 16, xl: 0 }}
            background="gray.200"
            backgroundImage={pfpImage}
            backgroundSize="cover"
            backgroundPosition="center center"
            backgroundRepeat="none"
            borderRadius={"400px"}
            order={{ base: 1, xl: 2 }}
          ></Box>
        </Flex>
        <Box
          aria-hidden
          w="400px"
          h="400px"
          borderRadius="400px"
          background="blue"
          position="absolute"
          top={-8}
          right={-8}
          zIndex={1}
          filter="blur(200px)"
          bgGradient={"linear(to-br, #5400ea, #e15f43)"}
        ></Box>
      </Box>
      <SectionFade startColor={"black"} endColor={"white"} height={400} />
    </>
  );
};

export default Hero;
