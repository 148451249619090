import { useEffect, useState } from "react";
import About from "./components/About";
import Contact from "./components/Contact";
import Hero from "./components/Hero";
import Portfolio from "./components/Portfolio";
import Skills from "./components/Skills";
import { Helmet } from "react-helmet";
import Preloader from "./components/Preloader";

const descText =
  "Victor Fuentes • Front-End Engineer & Graphic/Visual Designer";

function App() {
  const [preloader, setPreloader] = useState(true);
  useEffect(() => {
    document.title = descText;
    setTimeout(() => {
      setPreloader(false);
    }, 2200);
  }, []);
  return (
    <>
      <Helmet>
        <title>Victor Fuentes</title>
        <meta name="description" content={descText} />
      </Helmet>
      <Preloader visible={preloader} />
      <Hero />
      <Portfolio />
      <About />
      <Skills />
      <Contact />
    </>
  );
}

export default App;
